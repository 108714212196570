import { Button } from '@bepro-travel/fe.ui';
import { addHours } from 'date-fns';
import md5 from 'md5';
import { Fragment, useState } from 'react';

const LockPage = () => {
  const unlocked = localStorage.getItem('unlocked');
  const [isVisible, setIsVisible] = useState(!unlocked || new Date(unlocked) < new Date());
  const [inputValue, setInputValue] = useState('');

  const onSubmit = () => {
    const code = md5(inputValue);

    if (code === 'a643b99a54e99c7ac88e2be3a2b2a3dd') {
      setIsVisible(false);
      localStorage.setItem('unlocked', addHours(new Date(), 2).toString());
    }
  };

  return (
    <Fragment>
      {isVisible && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: '#fff',
            zIndex: 1000,
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <img
            src={require('../assets/img/logo.png')}
            alt=""
            style={{ margin: '100px auto 50px' }}
            height={70}
            width={250}
          />
          <h1 className="mb-20" style={{ fontSize: '1.1rem' }} dir="ltr">
            Web site is in development. Release coming soon ....
          </h1>
          <h1 className="mb-20" style={{ fontSize: '1.1rem' }}>
            אתר אינטרנט בפיתוח. שחרור בקרוב...
          </h1>

          <input
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue}
            type="password"
            className="form-control"
            style={{ width: 400, margin: '0 auto 20px' }}
            height={36}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSubmit();
              }
            }}
          />
          <Button
            style={{ width: 400, margin: ' 0 auto' }}
            title="Submit"
            onClick={onSubmit}
          ></Button>
        </div>
      )}
    </Fragment>
  );
};

export default LockPage;
